<script lang="ts">
  import type {RadioEquipmentRightProps} from "./RadioEquipmentRegulation";
  import {TemplateText} from "./TextTemplate";


  let {size = "50", minPower, maxPower, usbPd = false}: RadioEquipmentRightProps = $props()

  let powerAltText = $derived(usbPd ? `Leistung: ${minPower} bis ${maxPower} Watt mit USB Power Delivery` : `Leistung: ${minPower} bis ${maxPower} Watt`)
  let powerIconText = $derived.by(() => {
      return TemplateText(size,minPower,maxPower,usbPd)
    }
  )


  let powerText = $derived(`${minPower}\u2009\u2013\u2009${maxPower}\u202FW`)
  let usbPdText: string = "USB\u2009PD"


</script>

{#if size === "100"}
    <div class="container--right container--right100">
        <svg class="container--right--icon" width="3.875rem" height="5.75rem" viewBox="0 0 62 92" fill="none"
             xmlns="http:/*                             */
             aria-label={powerAltText}>
            <title>{powerAltText}</title>
            <rect x="1" y="31" width="60" height="60" rx="2" fill="white" stroke="#212121"/>
            <path d="M11 16.5C11 16.2239 11.2239 16 11.5 16H50.5C50.7761 16 51 16.2239 51 16.5V31H11V16.5Z" fill="white"
                  stroke="#212121"/>
            <path d="M18.5 2C18.5 1.44772 18.9477 1 19.5 1H22.5C23.0523 1 23.5 1.44772 23.5 2V16H18.5V2Z" fill="white"
                  stroke="#212121"/>
            <path d="M38.5 2C38.5 1.44772 38.9477 1 39.5 1H42.5C43.0523 1 43.5 1.44772 43.5 2V16H38.5V2Z" fill="white"
                  stroke="#212121"/>
            {@html powerIconText}
        </svg>
    </div>
{:else}
    <div class="container--right container--right50">
        <svg class="container--right--icon" style="flex-shrink: 0" width="1.625rem" height="2.375rem"
             viewBox="0 0 26 38" fill="none"
             xmlns="http:/*                                                        */
            <title>{powerAltText}</title>
            <path d="M8 1.5C8 1.22386 8.22386 1 8.5 1H9.5C9.77614 1 10 1.22386 10 1.5V7H8V1.5Z" fill="white"
                  stroke="#212121"/>
            <path d="M16 1.5C16 1.22386 16.2239 1 16.5 1H17.5C17.7761 1 18 1.22386 18 1.5V7H16V1.5Z" fill="white"
                  stroke="#212121"/>
            <path d="M5 7.5C5 7.22386 5.22386 7 5.5 7H20.5C20.7761 7 21 7.22386 21 7.5V13H5V7.5Z" fill="white"
                  stroke="#212121"/>
            <rect x="1" y="13" width="24" height="24" rx="2" fill="white" stroke="#212121"/>
            {@html powerIconText}
        </svg>
        <div class="container--right50--text" aria-hidden="true">
            <span class="container--right50--power-text">{powerText}</span>
            {#if usbPd === true}
                <span class="container--right50--usb-text">{usbPdText}</span>
            {/if}
        </div>
    </div>
{/if}

<style lang="scss">
  .container--right {

    display: flex;
    align-items: center;
    font-family: var(--oc-base-font-otto-sans);

    &-icon-text {
      padding: 0 1px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      height: 100%;
    }

    &50 {
      flex: 1 2 4.375rem;
      padding: 0.125rem 0.25rem 0.125rem 0.125rem;
      gap: 0.5rem;
      background-color: white;
      border-radius: 0.0625rem;
      border: 0.0625rem solid #212121;

      &--icon-power-text {
        font-size: 5px;
        font-weight: bold;
        line-height: 5.632px;
      }

      &--icon-usb-text {
        font-size: 4px;
        line-height: 4.496px;
      }

      &--text {
        display: flex;
        flex-direction: column;
      }

      &--power-text {
        word-wrap: break-word;
        font-size: 0.75rem;
        font-weight: bold;
        line-height: 0.844rem;
      }

      &--usb-text {
        font-size: 0.625rem;
        line-height: 0.703rem;
      }
    }

    &100 {
      width: fit-content;

      &--icon-power-text {
        font-size: 14px;
        font-weight: bold;
        line-height: 15.744px;
      }

      &--icon-usb-text {
        font-size: 12px;
        line-height: 12px;
      }
    }
  }
</style>