export const TemplateText = (size: string, minPower: number, maxPower: number, usbPd: boolean = false) => {
    const a = size === "100" ? 60 : 24; /*                                                                                                                           */

    const fontSize = size === "100" ? 14 : 5;
    const lineHeight = size === "100" ? 15.75 : 5.63;
    const lineHeightUSBPD = size === "100" ? 12 : 4.5;
    const distanceToNextLineBoxBold = (lineHeight - fontSize) / 2;
    const fontSizeUSBPD = size === "100" ? 12 : 4;
    const wattLineBreak = `${minPower}${maxPower}`.length > 3
    const maxPowerLineBreak = `${minPower}${maxPower}`.length > 4

    const yPowerText = () => {
        if (maxPowerLineBreak || wattLineBreak) {
            return usbPd ? a - lineHeight / 2 - distanceToNextLineBoxBold : a - distanceToNextLineBoxBold * 2;
        }
        return usbPd ? a - distanceToNextLineBoxBold : a + fontSize / 2 - distanceToNextLineBoxBold;
    }

    const yUsbPdText = () => {
        if ((maxPowerLineBreak || wattLineBreak) && usbPd) {
            return a + lineHeightUSBPD / 2 + lineHeight;
        }
        return a + lineHeightUSBPD;
    }

    const wattText = () => {
        if (maxPowerLineBreak) {
            return `
                <text x="50%" y="${yPowerText()}" text-anchor="middle" fill="#000" font-weight="bold" font-size="${fontSize}px" aria-hidden="true">
                     <tspan x="50%" dy="0">${minPower}\u2009\u2013</tspan>
                     <tspan x="50%" dy="${lineHeight}">${maxPower}\u2009W</tspan>
                </text>
        `
        } else if (wattLineBreak) {
            return `   
               <text x="50%" y="${yPowerText()}" text-anchor="middle" fill="#000" font-weight="bold" font-size="${fontSize}px" aria-hidden="true">
                    <tspan x="50%" dy="0">${minPower}\u2009\u2013\u2009${maxPower}</tspan>
                    <tspan x="50%" dy="${lineHeight}">W</tspan>
               </text>
            `
        } else {
            return `
                <text x="50%" y="${yPowerText()}" text-anchor="middle" fill="#000" font-weight="bold" font-size="${fontSize}px" aria-hidden="true">
                <tspan x="50%" dy="0">${minPower}\u2009\u2013\u2009${maxPower}\u2009W</tspan>
                </text>
            `
        }
    }

    const usbPdText = () => {
        return usbPd? `
            <text x="50%" y="${yUsbPdText()}" text-anchor="middle" fill="#000" font-size="${fontSizeUSBPD}px" aria-hidden="true">
                USB\u2009PD
            </text>
      `:""
    }

    return wattText()+usbPdText()
}

