<script lang="ts">
  import type {RadioEquipmentLeftProps} from "./RadioEquipmentRegulation";

  let {chargingDevice, size}: RadioEquipmentLeftProps = $props()
  let chargingDeviceAltText: string = $derived(chargingDevice ? "Ladenetzteil im Lieferumfang enthalten" : "Ladenetzteil nicht im Lieferumfang enthalten")
</script>

{#if size === "100"}
    <svg class="container--left" width="5rem" height="6.875rem" viewBox="0 0 80 110" fill="none" xmlns="http:/*                                                                 */
        <title>{chargingDeviceAltText}</title>
        <rect x="0.5" y="0.5" width="79" height="109" rx="0.5" fill="white" stroke="#212121"/>
        <rect x="10" y="40" width="60" height="60" rx="2" fill="white" stroke="#212121"/>
        <path d="M20 25.5C20 25.2239 20.2239 25 20.5 25H59.5C59.7761 25 60 25.2239 60 25.5V40H20V25.5Z" fill="white" stroke="#212121"/>
        <path d="M27.5 11C27.5 10.4477 27.9477 10 28.5 10H31.5C32.0523 10 32.5 10.4477 32.5 11V25H27.5V11Z" fill="white" stroke="#212121"/>
        <path d="M47.5 11C47.5 10.4477 47.9477 10 48.5 10H51.5C52.0523 10 52.5 10.4477 52.5 11V25H47.5V11Z" fill="white" stroke="#212121"/>
        {#if !chargingDevice}
            <path class="container--left--no-charging-device" d="M1 1L79 109" stroke="#212121" stroke-width="2" stroke-linecap="round"/>
        {/if}
    </svg>
{:else}
    <svg class="container--left" width="2rem" height="2.75rem" viewBox="0 0 32 44" fill="none" xmlns="http:/*                                                                 */
        <title>{chargingDeviceAltText}</title>
        <rect x="0.5" y="0.5" width="31" height="43" rx="0.5" fill="white" stroke="#212121"/>
        <path d="M11 4.5C11 4.22386 11.2239 4 11.5 4H12.5C12.7761 4 13 4.22386 13 4.5V10H11V4.5Z" fill="white" stroke="#212121"/>
        <path d="M19 4.5C19 4.22386 19.2239 4 19.5 4H20.5C20.7761 4 21 4.22386 21 4.5V10H19V4.5Z" fill="white" stroke="#212121"/>
        <path d="M8 10.5C8 10.2239 8.22386 10 8.5 10H23.5C23.7761 10 24 10.2239 24 10.5V16H8V10.5Z" fill="white" stroke="#212121"/>
        <rect x="4" y="16" width="24" height="24" rx="2" fill="white" stroke="#212121"/>
        {#if !chargingDevice}
            <path class="container--left--no-charging-device" d="M1 1L31 43" stroke="#212121" stroke-width="2" stroke-linecap="round"/>
        {/if}
    </svg>
{/if}