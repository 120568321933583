<svelte:options
        customElement={{
    tag: "ocf-radio-equipment-regulation-v1",
    props: {
      size: { type: "String", reflect: true },
      inheritWidth: { type: "Boolean", attribute:"inherit-width",reflect: true },
      minPower: { type: "Number",attribute: "min-power", reflect: true },
      maxPower: { type: "Number", attribute: "max-power", reflect: true },
      usbPd: { type: "Boolean", attribute: "usb-pd", reflect: true },
      chargingDevice: { type: "Boolean",attribute: "charging-device",reflect: true},
    }
  }}
/>

<script lang="ts">
  import type {RadioEquipmentRegulationProps} from "./RadioEquipmentRegulation";
  import RadioEquipmentIconRight from "./RadioEquipmentIconRight.svelte";
  import RadioEquipmentIconLeft from "./RadioEquipmentIconLeft.svelte";

  let {size = "50", minPower, maxPower, usbPd = false, chargingDevice = false}: RadioEquipmentRegulationProps = $props()
</script>

<div class="radio-equipment-regulation radio-equipment-regulation--size-{size}">
    <RadioEquipmentIconLeft size={size} chargingDevice={chargingDevice}></RadioEquipmentIconLeft>
    <RadioEquipmentIconRight size={size} minPower={minPower} maxPower={maxPower}
                             usbPd={usbPd}></RadioEquipmentIconRight>
</div>

<style lang="scss">
  :host {
    display: block;
    width: fit-content;
  }

  :host([inherit-width]) {
    width: inherit;
  }

  .radio-equipment-regulation {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    &--size-50 {
      gap: 0.25rem;
    }

    &--size-100 {
      gap: 0.75rem;
    }
  }
</style>